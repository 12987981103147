import React from 'react';
import '../styles/home.css';

const Hero = () => {
  return (
    <>
    <div className="curveContainer">
      <svg className='curveDiv heroCurveTop' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fillOpacity="1" d="M0,160L48,149.3C96,139,192,117,288,117.3C384,117,480,139,576,154.7C672,171,768,181,864,181.3C960,181,1056,171,1152,144C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
    <section className="heroMain">
      <div className="heroContainer">
        <h1 className="heroTitle">Think big.<br />Create BIG.</h1>
        {/* <p className="heroParagraph">
          Welcome to GrDev! We are a full-service web development and analytics company.
          From design to development, we create stunning web applications that
          drive results. With our expertise in Google Tag Manager and Google
          Analytics, we provide powerful analytics solutions to track and
          optimize your online presence.
        </p>
        <p className="heroParagraph">
          Our team of experienced full stack developers can handle every aspect
          of your project, from initial concept to ongoing maintenance and
          troubleshooting. We offer services such as fixing, upgrading, and
          migrating existing systems to ensure your business stays ahead of the
          curve.
        </p>
        <p className="heroParagraph">
          Partner with us to unlock the full potential of your web presence. Get
          in touch today to discuss your project and discover how we can help
          you achieve your goals.
        </p> */}
        <p className="heroDescription">
         We are a full-service analytics and web development company, providing end-to-end solutions from design to maintenance, and so much more.
        </p>
        <button className="heroButton">Get Started</button>
      </div>
    </section>
    <div className="heroCurvBottom">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fillOpacity="1" d="M0,160L48,149.3C96,139,192,117,288,117.3C384,117,480,139,576,154.7C672,171,768,181,864,181.3C960,181,1056,171,1152,144C1248,117,1344,75,1392,53.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
    </div>
    </>
  );
};

export default Hero;

// import React from 'react';
// import '../styles/home.css'; // Import the CSS file for styling

// const HeroSection = () => {
//   return (
//     <section className="hero">
//       <div className="hero-content">
//         <h1 className="hero-title">Welcome to GrDev</h1>
//         <p className="hero-description">
//           We are a full-service web development and analytics company, providing end-to-end solutions from design to maintenance.
//         </p>
//         <button className="hero-button">Get Started</button>
//       </div>
//     </section>
//   );
// };

// export default HeroSection;
