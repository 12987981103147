import React from 'react';

const Testamonials = () => {
  return (
    <>
    <div>testamonials</div>
    </>
  );
}

export default Testamonials;
