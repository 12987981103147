import '../styles/home.css';
import jandj from '../assets/jandj.jpg';
import aveenologo from '../assets/aveenologo.png';
import baroidlogo from '../assets/baroidlogo.jpeg';
import lookfarlogo from '../assets/lookfarlogo.png';
import gretrixlogo from '../assets/gretrixlogo.png';
import neutrogena from '../assets/neutrogenalogo.png';
import mobilequbeslogo from '../assets/mobilequbeslogo.jpeg';

const PartneredBadgeList = () => {
  return (
    <>
    <div className="curveContainer">
      <svg className='curveDiv partnerCurveTop' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#d3d3d3" fillOpacity="1" d="M0,160L48,149.3C96,139,192,117,288,117.3C384,117,480,139,576,154.7C672,171,768,181,864,181.3C960,181,1056,171,1152,144C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
    <div className="partnerMain">
      <span className="partnerTitle">Companies we have worked with</span>
      <span className="partnerBadges">
        <img src={baroidlogo} alt="Halliburton" className="partnerLogo baroid" />
        <img src={gretrixlogo} alt="Gretrix" className="partnerLogo gretrix" />
        <img src={mobilequbeslogo} alt="MobileQubes" className="partnerLogo mobilequbes" />
        <img src={lookfarlogo} alt="Look Far Labs" className="partnerLogo lookfar" />
        <img src={jandj} alt="Johnson & Johnson" className="partnerLogo jandj" />
        <img src={aveenologo} alt="Aveeno" className="partnerLogo aveeno" />
        <img src={neutrogena} alt="Neutrogena" className="partnerLogo neutrogena" />
      </span>
    </div>
    <div className="curveContainer curveContainerBottom">
    <svg className='curveDiv partnerCurveBottom' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#d3d3d3" fillOpacity="1" d="M0,192L60,213.3C120,235,240,277,360,293.3C480,309,600,299,720,261.3C840,224,960,160,1080,133.3C1200,107,1320,117,1380,122.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
    </div>
    </>
  );
}

export default PartneredBadgeList;
