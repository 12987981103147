import CaseStudies from "../components/CaseStudies";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import NavMain from "../components/NavMain";
import PartneredBadgeList from "../components/PartneredBadgeList";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Testamonials from "../components/Testamonials";
import '../styles/home.css';

const Home = () => {
  return (
    <div className="homeContainer">
      <NavMain />
      <Hero />
      <PartneredBadgeList />
      <Services />
      <Portfolio />
      <CaseStudies />
      <Testamonials />
      <Footer />
    </div>
  );
}

export default Home;
