import React from 'react';

const Services = () => {
  // Sample list of services (you can replace these with your actual services)
  const services = [
    {
      name: 'Software / Web Development',
      svcType: [
        {
          name: 'Front-End Services',
          description: 'This is the description of Service 1.',
        },
        {
          name: 'Back-End Services',
          description: 'This is the description of Service 1.',
        },
        {
          name: 'Full-Stack Services',
          description: 'This is the description of Service 1.',
        },
        {
          name: 'Bug Fixes',
          description: 'This is the description of Service 1.',
        },
        {
          name: 'Troubleshooting',
          description: 'This is the description of Service 1.',
        },
        {
          name: 'Full or Partial Migrations',
          description: 'This is the description of Service 1.',
        }
      ]
    },
    {
      name: 'Mobile Development',
      svcType: [
        {
          name: 'iOS Development using React Native',
          description: 'This is the description of iOS React Native services',
        },
        {
          name: 'Android Development in React Native',
          description: 'This is the description of Android React Native services',
        },
      ]
    },
    {
      name: 'Data Analytics',
      svcType: [
        {
          name: 'Full Service Google Tag Manager',
          description: 'This is the description of GTM services.',
        },
        {
          name: 'Full Service Google Analytics',
          description: 'This is the description of GA services.',
        },
        {
          name: "Full Service Analytics Services",
          description: "This is the description of other Analytics services."
        },
        {
          name: "Full Service Google Ads/SEO",
          description: "This is the description of Google Ads/SEO."
        }
      ],
    },
    {
      name: 'Scripting & Automation',
      svcType: [
        {
          name: 'Scripting Services',
          description: 'This is the description of Service 3.',
        },
        {
          name: 'Automation Services',
          description: 'This is the description of Service 3.'
        },
      ]
    },
  ];
  // const getServiceInfo:any = (services:any) => {
  //   return (
  //     services.map((service:any, index:any) => {
  //       return (
  //         service.svcType.map((svc:any, index:any) => {
  //           // console.log(svc.name)
  //           return (<p>{svc.name}</p>)
  //         })
  //       )
  //     })
  //   )
  // }

  const getServiceInfo:any = (service:any, index:any) => {
    // console.log(service)
    return (<p key={index} className='servicesOffered'>{service}</p>)
  }

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);  

  return (
    <div className='servicesContainer'>
      <h2 className='servicesHeading'>What We Offer:</h2>
      {services.map((service:any, index) => (
        <div className='serviceItem reveal' key={index}>
          <h3 className='serviceName'>{service.name}</h3>
          {/* <p>service description</p> */}
          <div className="serviceItemContainer">
            {service.svcType.map((ser:any, index:any) => {
              return ( <div key={index} className="serviceOfferedContainer">{getServiceInfo(ser.name, index)}</div> )
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Services;

// CSS styles (inline for simplicity, you can move these to a separate CSS file)
// const styles:any = {
//   servicesContainer: {
//     fontFamily: 'Arial, sans-serif',
//     maxWidth: '600px',
//     position: 'relative',
//     bottom: '5.5rem',
//   },
//   servicesHeading: {
//     textAlign: 'center',
//     color: '#333',
//     position: 'relative',
//     bottom: '1rem',
//   },
//   serviceItem: {
//     marginBottom: '20px',
//     padding: '10px',
//     borderRadius: '5px',
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//     backgroundColor: '#f9f9f9',
//   },
//   serviceName: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     color: '#333',
//   },
// };
