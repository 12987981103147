import React from 'react';

const CaseStudies = () => {
  return (
    <>
    <div>case studies</div>
    </>
  );
}

export default CaseStudies;
