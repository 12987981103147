import React from "react";
import wrgcv from "../assets/wrgcv.png";
import mobilequbes from "../assets/mobilequbes.png";
import GulfCoastCorgis from "../assets/GulfCoastCorgis.png";

const Portfolio = () => {
  const portfolioItems = [
    {
      name: "Gulf Coast Corgis",
      image: `${GulfCoastCorgis}`,
      link: "https://gulfcoastcorgis.com/",
      description: "Description for Gulf Coast Corgis will go here",
    },
    {
      name: "wrgcv.com",
      image: `${wrgcv}`,
      link: "https://wrgcv.com/",
      description: "Description for wrgcv.com will go here",
    },
    {
      name: "Mobilequbes ",
      image: `${mobilequbes}`,
      link: "https://mobilequbes.com/",
      description: "Description for MobileQubes will go here",
    },
  ];

  const showItems: any = () => {
    return (
      <div className="">
        {portfolioItems.map((portfolio: any, item: any) => {
          console.log(portfolio.image);
          return (
            <ul className="portfolioItemContainer">
              <li className="portfolioItemNameContainer">
                <h3 className="portfolioItemName">{portfolio.name}</h3>
              </li>
              <li className="portfolioItem" key={item}>
                <img
                  src={portfolio.image}
                  alt={portfolio.name}
                  className="portfolioImage"
                />
              </li>
              <li className="portfolioItemDescription">
                <p className="portfolioDescription">{portfolio.description}</p>
              </li>
            </ul>
          );
        })}
      </div>
    );
  };

  return (
    <div className="portfolioContainer">
      <div className="portfolioCurveContainerTop">
        <svg
          className="portfolioCurveTop"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#f8f8f8"
            fillOpacity="1"
            d="M0,160L60,165.3C120,171,240,181,360,202.7C480,224,600,256,720,224C840,192,960,96,1080,85.3C1200,75,1320,149,1380,186.7L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
      <h2 className="portfolioHeader">Our Latest Work</h2>
      <>{showItems()}</>
      <div className="portfolioCurveContainerBottom">
        <svg
          className="portfolioCurveBottom"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#f8f8f8"
            fillOpacity="1"
            d="M0,160L60,165.3C120,171,240,181,360,202.7C480,224,600,256,720,224C840,192,960,96,1080,85.3C1200,75,1320,149,1380,186.7L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Portfolio;
