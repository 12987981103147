import Home from '../screens/Home';
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
