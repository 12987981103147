import { useState } from "react";
import "../styles/home.css";
import logo192 from "../assets/logo192.png";

const NavMain = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    // <div className="navContainer">
    //   <img className="navLogo" src={logo192} alt="GrDev logo" />
    //   <ul className="navServices">
    //     <li><a href="#">services</a></li>
    //     <li><a href="#">portfolio</a></li>
    //     <li><a href="#">contact</a></li>
    //   </ul>
    // </div>
    <>
      <div
        className={`menu navBtn ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="icon"></div>
        <nav className={`nav ${menuOpen ? "navMenu" : ""}`} id="nav">
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Services</a>
            </li>
            <li>
              <a href="#">Portfolio</a>
            </li>
            <li>
              <a href="#">Case Studies</a>
            </li>
            <li>
              <a href="#">Testamonials</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul>
        </nav>

        <div
          className={`menu-bg ${menuOpen ? "change-bg" : ""}`}
          id="menu-bg"
        ></div>
      </div>
    </>
  );
};

export default NavMain;
